<template>
  <el-row type="flex" class="row-bg" justify="space-between">
    <top-categories
      ref="top-categories"
      :start="start"
      :end="end"
      :staff="staff"
      :selected-branch="selectedBranch"
    />
    <refunds
      ref="refunds"
      :start="start"
      :end="end"
      :staff="staff"
      :selected-branch="selectedBranch"
    />
    <top-items
      ref="top-items"
      :start="start"
      :end="end"
      :staff="staff"
      :selected-branch="selectedBranch"
    />
  </el-row>
</template>

<script>
import TopCategories from './topCategories'
import Refunds from './refunds'
import TopItems from './topItems'

export default {
  components: {
    TopCategories,
    Refunds,
    TopItems
  },
  props: {
    end: {
      type: Date,
      required: false,
      default: undefined
    },
    start: {
      type: Date,
      required: false,
      default: undefined
    },
    staff: {
      type: String,
      required: false,
      default: undefined
    },
    selectedBranch: {
      type: [String, Number],
      required: false,
      default: undefined
    }
  },
  data() {
    return {}
  },
  methods: {
    refresh() {
      this.$nextTick(() => {
        this.$refs['top-categories'].refresh()
        this.$refs.refunds.refresh()
        this.$refs['top-items'].refresh()
      })
    }
  }
}
</script>

<style scoped>
.statistics-app,
.statistics-app > * {
  height: 100%;
}

.statistics-app {
  overflow-y: hidden;
}

.no-url {
  background-color: #525f69;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  font-size: 200%;
  user-select: none;
}

.no-url p {
  white-space: pre-line;
  text-align: center;
}

.card {
  width: 100%;
  margin: 15px;
}

.card h2 {
  text-align: center;
}

.card :deep(.cell) {
  word-break: break-word;
}

.card :deep(tr td:last-child) {
  color: #a7abb1;
}
</style>
