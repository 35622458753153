<template>
  <el-card v-loading="loading" class="staff-card">
    <h2>{{ $t('pages.reports.statistics.staff.refunds') }}</h2>
    <div>
      <el-table :data="refunds">
        <el-table-column show-overflow-tooltip prop="paymentOption" />
        <el-table-column
          show-overflow-tooltip
          prop="sumRefundsAmount"
          align="right"
        />
        <el-table-column
          show-overflow-tooltip
          prop="sumRefunds"
          align="right"
        />
      </el-table>
    </div>
    <section class="refunds-summary">
      <div>
        {{ $t('pages.reports.statistics.staff.total') }}: {{ sumRefunds }}
      </div>
    </section>
  </el-card>
</template>

<script>
import th from '@tillhub/javascript-sdk'

export default {
  name: 'Refunds',
  props: {
    end: {
      type: Date,
      required: false,
      default: undefined
    },
    start: {
      type: Date,
      required: false,
      default: undefined
    },
    staff: {
      type: String,
      required: false,
      default: undefined
    },
    selectedBranch: {
      type: [String, Number],
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      locale: this.$i18n.locale,
      refunds: null,
      loading: false,
      defaultCurrency: this.$store.getters['Config/getCurrentDefaultCurrency']
    }
  },
  computed: {
    sumRefunds() {
      if (!Array.isArray(this.refunds) || !this.refunds.length) return '–'
      return this.refunds
        .map((refund) => refund.sumRefunds)
        .reduce((acc, curr) => Number(acc) + Number(curr), 0)
        .toLocaleString(this.locale)
    }
  },
  async mounted() {
    await this.fetch()
  },
  methods: {
    async fetch() {
      try {
        const { data } = await th.analytics().getRefundsReport({
          staff: this.staff || undefined,
          query: {
            end: this.end ? this.end.toISOString() : undefined,
            start: this.start ? this.start.toISOString() : undefined,
            branch_number: this.selectedBranch || undefined
          }
        })

        this.refunds = data[0].values.map((val) => ({
          paymentOption: val.payment_option,
          sumRefundsAmount: this.$formatCurrency(
            val.sum_refunds_amount,
            this.defaultCurrency
          ),
          sumRefunds: val.sum_refunds
        }))
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },
    refresh() {
      this.$nextTick(() => {
        this.fetch()
      })
    }
  }
}
</script>

<style scoped>
.staff-card {
  width: 100%;
  margin: 0px;
  margin-right: 25px;
}

.staff-card h2 {
  text-align: center;
}

.staff-card :deep(.el-card__body) {
  height: 97%;
  display: flex;
  flex-direction: column;
}

.el-table::before {
  background-color: transparent;
}

.refunds-summary {
  padding-bottom: 20px;
  padding-top: 20px;
  font-size: 20px;
  display: flex;
  justify-content: center;
}

.el-table :deep(.el-table__header-wrapper) {
  display: none;
}
</style>
