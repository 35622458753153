<template>
  <el-card v-loading="loading" class="staff-card">
    <h2>{{ $t('pages.reports.statistics.staff.top_categories') }}</h2>
    <el-table :data="productGroups">
      <el-table-column type="index" />
      <el-table-column show-overflow-tooltip prop="productGroup" />
      <el-table-column show-overflow-tooltip prop="amount" align="right" />
      <el-table-column
        show-overflow-tooltip
        prop="total"
        min-width="30px"
        align="right"
      />
    </el-table>
  </el-card>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import { DEFAULT_PRODUCT_GROUP_NAME } from '../constants/index'

export default {
  name: 'TopCategories',
  props: {
    end: {
      type: Date,
      required: false,
      default: undefined
    },
    start: {
      type: Date,
      required: false,
      default: undefined
    },
    staff: {
      type: String,
      required: false,
      default: undefined
    },
    selectedBranch: {
      type: [String, Number],
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      productGroups: null,
      loading: false
    }
  },
  async mounted() {
    await this.fetch()
  },
  methods: {
    async fetch() {
      this.loading = true
      try {
        const { data } = await th.analytics().getProductGroupsStaffReport({
          staff: this.staff || undefined,
          query: {
            end: this.end ? this.end.toISOString() : undefined,
            start: this.start ? this.start.toISOString() : undefined,
            branch_number: this.selectedBranch || undefined
          }
        })

        this.productGroups = data[0].values
          .sort((a, b) => b.amount_gross_total - a.amount_gross_total)
          .slice(0, 5)
          .map((val, i) => ({
            index: i,
            productGroup: val.product_group_name || DEFAULT_PRODUCT_GROUP_NAME,
            // TODO: get locale string from the configurations object
            amount: val.amount_gross_total.toLocaleString('de-DE', {
              style: 'currency',
              currency: val.currency
            }),
            total: val.transactions_total
          }))
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },
    refresh() {
      this.$nextTick(() => {
        this.fetch()
      })
    }
  }
}
</script>

<style scoped>
.staff-card {
  width: 100%;
  margin: 0px;
  margin-right: 25px;
}

.staff-card h2 {
  text-align: center;
}

.el-table :deep(.el-table__header-wrapper) {
  display: none;
}
</style>
