<template>
  <el-card v-loading="loading" class="staff-card">
    <h2>{{ $t('pages.reports.statistics.staff.overview') }}</h2>
    <el-table :data="displayData" style="width: 100%" height="200px">
      <el-table-column
        :label="$t('pages.reports.statistics.staff.staff')"
        prop="staff"
        :min-width="140"
        show-overflow-tooltip
      />
      <el-table-column
        :label="$t('pages.reports.statistics.staff.name')"
        prop="name"
        :min-width="180"
        show-overflow-tooltip
      >
        <template #default="scope">
          {{ getStaffName(scope.row) || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('pages.reports.statistics.staff.items_sold')"
        align="right"
        prop="quantity"
        :min-width="140"
        show-overflow-tooltip
      />
      <el-table-column
        :label="$t('pages.reports.statistics.staff.revenue')"
        align="right"
        :min-width="120"
        show-overflow-tooltip
      >
        <template #default="scope">
          <div>{{ scope.row.revenue }}</div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('pages.reports.statistics.staff.discount')"
        align="right"
        :min-width="120"
        show-overflow-tooltip
      >
        <template #default="scope">
          <div>{{ scope.row.discount }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <div class="block">
        <el-pagination
          v-model:current-page="currentPage"
          small
          :page-sizes="[3, 5, 7, 10, 13]"
          :page-size="pageSizes.inline"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableCount"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </el-row>
  </el-card>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import { DEFAULT_USER } from '../constants/index'

export default {
  name: 'StaffOverview',
  props: {
    end: {
      type: Date,
      required: false,
      default: undefined
    },
    start: {
      type: Date,
      required: false,
      default: undefined
    },
    staff: {
      type: String,
      required: false,
      default: undefined
    },
    selectedBranch: {
      type: [String, Number],
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      currentPage: 1,
      staffOverview: null,
      staffOverviewCount: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      pageSizes: 'Config/getPageSizes'
    }),
    displayData() {
      if (!this.staffOverview || this.staffOverview.length === 0) return []
      const newStaff = this.staffOverview
      const pageStart = (this.currentPage - 1) * this.pageSizes.inline
      const pageEnd = pageStart + this.pageSizes.inline
      const visableStaff = newStaff.slice(pageStart, pageEnd).map((val) => ({
        staff: val.staff_number || DEFAULT_USER,
        staffID: val.staff_id,
        staff_number: val.staff_number,
        firstname: val.first_name,
        lastname: val.last_name,
        quantity: val.quantity,
        revenue: this.$formatCurrency(val.amount_gross_total, val.currency),
        discount: this.$formatCurrency(val.discount_amount_total, val.currency)
      }))
      return visableStaff
    },
    tableCount() {
      if (!this.staffOverview) return
      return this.staffOverview.length
    }
  },
  async mounted() {
    await this.fetch()
  },
  methods: {
    handleSizeChange(val) {
      this.pageSizes.inline = val
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    getStaffName(item) {
      return this.$formatStaff(item, ['fullName']) || DEFAULT_USER || '--'
    },

    async fetch() {
      this.loading = true
      try {
        const { data } = await th.analytics().getStaffOverviewReport({
          staff: this.staff || undefined,
          query: {
            end: this.end ? this.end.toISOString() : undefined,
            start: this.start ? this.start.toISOString() : undefined,
            branch_number: this.selectedBranch || undefined
          }
        })

        this.staffOverview = data.find(
          (item) => item.metric.job === 'reports_staff'
        ).values
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },
    refresh() {
      this.$nextTick(() => {
        this.fetch()
      })
    }
  }
}
</script>

<style scoped>
.el-row {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  flex-direction: row;
}

.cell {
  white-space: pre-wrap;
  word-break: keep-all;
}

.no-url p {
  white-space: pre-line;
  text-align: center;
}

.staff-card {
  margin: 25px 0px;
}

.staff-card h2 {
  text-align: center;
}
</style>
