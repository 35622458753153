<template>
  <el-card v-loading="loading" class="staff-card">
    <h2>{{ $t('pages.reports.statistics.staff.top_items') }}</h2>
    <el-table :data="items">
      <el-table-column type="index" />
      <el-table-column show-overflow-tooltip prop="name" />
      <el-table-column show-overflow-tooltip prop="amount" align="right" />
      <el-table-column show-overflow-tooltip prop="quantity" align="right" />
    </el-table>
  </el-card>
</template>

<script>
import th from '@tillhub/javascript-sdk'

export default {
  name: 'TopItems',
  props: {
    end: {
      type: Date,
      required: false,
      default: undefined
    },
    start: {
      type: Date,
      required: false,
      default: undefined
    },
    staff: {
      type: String,
      required: false,
      default: undefined
    },
    selectedBranch: {
      type: [String, Number],
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      items: null,
      loading: false,
      defaultCurrency: this.$store.getters['Config/getCurrentDefaultCurrency']
    }
  },
  computed: {},
  async mounted() {
    await this.fetch()
  },
  methods: {
    async fetch() {
      this.loading = true
      try {
        const { data } = await th.analytics().getProductsReport({
          staff: this.staff || undefined,
          query: {
            end: this.end ? this.end.toISOString() : undefined,
            start: this.start ? this.start.toISOString() : undefined,
            branch_number: this.selectedBranch || undefined
          }
        })

        this.items = data[0].values
          .sort((a, b) => b.amountGrossTotal - a.amountGrossTotal)
          .slice(0, 5)
          .map((val, i) => ({
            index: i,
            name: val.productName,
            amount: this.$formatCurrency(
              val.amountGrossTotal,
              this.defaultCurrency
            ),
            quantity: val.quantity
          }))
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },
    refresh() {
      this.$nextTick(() => {
        this.fetch()
      })
    }
  }
}
</script>

<style scoped>
.staff-card {
  width: 100%;
  margin: 0px;
}

.staff-card h2 {
  text-align: center;
}

.el-table :deep(.el-table__header-wrapper) {
  display: none;
}
</style>
