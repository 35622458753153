<template>
  <new v-if="isNew" :resources="resources" @change-view="changeView(false)" />
  <old v-else :resources="resources" @change-view="changeView(true)" />
</template>

<script>
import New from './index-new.vue'
import Old from './index-old.vue'
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import qs from 'qs'
import { applyFiltersBeforeRouteEnter } from '@/utils/date'

export default {
  components: {
    New,
    Old
  },
  beforeRouteEnter: (to, from, next) => {
    // doing stuff here is very dangerous as it might lead to infinite route loops
    applyFiltersBeforeRouteEnter({ path: to.path, query: to.query, next })
  },
  beforeRouteUpdate(to, from, next) {
    this.$emit('route-filter', safeGet(qs.parse(to.query), 'filter') || {})
    next()
  },
  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      isNew: 'Config/getStaffSalesReportViewNew'
    })
  },
  methods: {
    changeView(isNewView) {
      this.$store.dispatch('Config/setStaffSalesReportViewNew', isNewView)
    }
  }
}
</script>

<style scoped></style>
