<template>
  <th-page-wrapper :key="$route.fullPath" class="box-border">
    <filter-header
      show-filter
      prune-search-filters
      do-route-filters
      resource="staff"
      route-base="staff"
      :search-filters="filtersList"
    />
    <staff-overview
      ref="overview-table"
      class="m-6"
      :start="date.start"
      :end="date.end"
      :staff="currentStaff"
      :selected-branch="branchNumber"
    />
    <multi-tables
      ref="multi-tables"
      class="m-6"
      :start="date.start"
      :end="date.end"
      :staff="currentStaff"
      :selected-branch="branchNumber"
    />
    <el-button class="float-right mb-6 mr-6" @click="handleShowNew()">{{
      $t('pages.reports.statistics.staff.go_to_new')
    }}</el-button>
  </th-page-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
import qs from 'qs'
import FilterHeader from '@/components/filter-header'
import StaffOverview from './staffOverview'
import MultiTables from './multiTables'
import { mapGetters } from 'vuex'
import {
  applyFiltersBeforeRouteEnter,
  formatDateRange,
  getDateTimeFormat,
  getRangeFor
} from '@/utils/date'

export default {
  metaInfo() {
    return {
      title: this.$t('pages.reports.statistics.staff.staff')
    }
  },
  components: {
    FilterHeader,
    MultiTables,
    StaffOverview
  },
  beforeRouteEnter: (to, from, next) => {
    // doing stuff here is very dangerous as it might lead to infinite route loops
    applyFiltersBeforeRouteEnter({ path: to.path, query: to.query, next })
  },
  beforeRouteUpdate(to, from, next) {
    this.$emit('route-filter', safeGet(qs.parse(to.query), 'filter') || {})
    next()
  },
  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show: false,
      locale: this.$i18n.locale
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'Auth/isAdmin',
      defaultDateSelected: 'Config/getDefaultDateSelected',
      branchNumber: 'Config/getCurrentBranchNumber'
    }),
    parsedQuery() {
      return safeGet(qs.parse(this.$route.query), 'filter', {})
    },
    date() {
      const { start, end } = safeGet(this.parsedQuery, 'date', {})
      return {
        start: start ? new Date(start) : undefined,
        end: end ? new Date(end) : undefined
      }
    },
    currentStaff() {
      return this.parsedQuery.staff
    },
    filtersList() {
      return [
        {
          name: 'staff',
          type: 'remote-search-select',
          label: this.$t('pages.reports.statistics.staff.staff'),
          resource: 'staff',
          computeName: (staff) =>
            this.$formatStaff(staff, ['staff_number', 'fullName'], ' - '),
          modifyQuery: (q) => ({
            q,
            fields: ['staff_number', 'lastname', 'firstname'],
            deleted: false
          })
        },
        {
          name: 'date',
          prop: ['start', 'end'],
          type: 'daterange',
          dateTimeMode: true,
          label: this.$t('pages.transactions.all.filters.date.label'),
          closable: false,
          autoClose: false,
          formatValue: (value) => formatDateRange(value, getDateTimeFormat()),
          default: getRangeFor[this.defaultDateSelected]?.(),
          modifyFilter: (filterObject) => ({
            start: filterObject.start,
            end: filterObject.end
          }),
          noFutureDates: true
        }
      ]
    }
  },
  mounted() {
    this.$emitter.on('refresh-requested', () => {
      this.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    refresh() {
      this.$nextTick(() => {
        this.$refs['overview-table'].refresh()
        this.$refs['multi-tables'].refresh()
      })
    },
    handleShowNew() {
      this.$emit('change-view')
    }
  }
}
</script>
