<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      do-route-filters
      headers-filterable
      no-meta-check
      prune-search-filters
      resource="staff"
      route-base="/reports/statistics/staff"
      show-filter
      sortable
      :buttons="computedButtons"
      :custom-resource="customResource()"
      :headers-config="headersConfig"
      :headers="headers"
      :locale="locale"
      :resource-query="resourceQuery"
      :search-filters="filtersList"
      :show-operations="false"
      @headers-config="handleHeadersConfig"
      @loading-error="handleLoadingError"
      @search-filter-submit="
        $ampli.eventWithBaseProps('statisticsFiltersSearchButtonClick')
      "
    />
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import { mapGetters } from 'vuex'
import qs from 'qs'
import datatableHeadersConfig from '@/mixins/datatable-headers-config'
import {
  applyFiltersBeforeRouteEnter,
  formatDateRange,
  getDateTimeFormat,
  getRangeFor
} from '@/utils/date'
import { useExportsStore } from '@/store/exports'

function parseStaffName(staff) {
  return `${staff.staff_number || ''} ${
    staff.staff_number && (staff.firstname || staff.lastname) ? '-' : ''
  } ${`${staff.firstname || ''} ${staff.lastname || ''}`.trim() || ''}`.trim()
}

export default {
  metaInfo() {
    return {
      title: this.$t('pages.reports.statistics.staffSales.staffSales')
    }
  },
  beforeRouteEnter: (to, _, next) => {
    // doing stuff here is very dangerous as it might lead to infinite route loops
    applyFiltersBeforeRouteEnter({ path: to.path, query: to.query, next })
  },
  beforeRouteUpdate(to, _, next) {
    this.$emit('route-filter', safeGet(qs.parse(to.query), 'filter') || {})
    next()
  },
  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { headersConfig, handleHeadersConfig } = datatableHeadersConfig(
      'settings.headerFilters.reports.statistics.staff'
    )
    return {
      headersConfig,
      handleHeadersConfig
    }
  },
  data() {
    return {
      isLegacy: true,
      loadingExport: false,
      filters: {},
      width: 500,
      headers: [
        {
          label: this.$t('pages.reports.statistics.staff.name'),
          field: 'staff_number',
          truncate: true,
          minWidth: 200,
          fallback: '-',
          formatter: (row) => {
            if (!row.staff_number) return '-'
            const staff = {
              firstname: row.firstname,
              lastname: row.lastname,
              staff_number: row.staff_number
            }
            if (!staff.firstname && !staff.lastname) return '-'
            return parseStaffName(staff) || '-'
          }
        },
        {
          label: this.$t('pages.reports.statistics.staff.product_name'),
          field: 'product_name',
          sortable: true,
          truncate: true,
          minWidth: 300,
          fallback: '-'
        },
        {
          label: this.$t('pages.reports.statistics.staff.product_number'),
          field: 'product_number',
          fallback: '-',
          minWidth: 200
        },
        {
          label: this.$t('pages.reports.statistics.staff.product_group_name'),
          field: 'product_group_name',
          fallback: '-',
          minWidth: 200
        },
        {
          label: this.$t('pages.reports.statistics.staff.quantity'),
          field: 'quantity',
          align: 'right',
          truncate: true,
          minWidth: 120,
          fallback: '-',
          sortType: 'number'
        },
        {
          label: this.$t('pages.reports.statistics.staff.net_revenue'),
          field: 'net_revenue',
          align: 'right',
          fallback: '-',
          truncate: true,
          minWidth: 140,
          formatter: (row) => {
            if (Number.isFinite(row.net_revenue)) {
              return this.$formatCurrency(row.net_revenue, this.defaultCurrency)
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.staff.discount'),
          field: 'discount',
          align: 'right',
          fallback: '-',
          truncate: true,
          minWidth: 140,
          formatter: (row) => {
            if (Number.isFinite(row.discount)) {
              return this.$formatCurrency(row.discount, this.defaultCurrency)
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.staff.gross_revenue'),
          field: 'gross_revenue',
          align: 'right',
          fallback: '-',
          truncate: true,
          minWidth: 140,
          formatter: (row) => {
            if (Number.isFinite(row.gross_revenue)) {
              return this.$formatCurrency(
                row.gross_revenue,
                this.defaultCurrency
              )
            }
            return '-'
          },
          sortType: 'currency'
        }
      ],
      buttons: [
        {
          type: 'custom_export',
          scopes: ['reports_statistics:staff:export'],
          label: this.$t('common.interactions.buttons.export'),
          clickHandler: () => {
            this.handleExport()
          }
        },
        {
          label: this.$t('pages.reports.statistics.staff.go_to_old'),
          clickHandler: () => {
            this.handleShowOld()
          }
        }
      ],
      summary: null
    }
  },
  computed: {
    ...mapGetters({
      branch: 'Config/getCurrentLocation',
      locale: 'Config/getLocale',
      defaultDateSelected: 'Config/getDefaultDateSelected',
      defaultCurrency: 'Config/getCurrentDefaultCurrency'
    }),
    filtersList() {
      return [
        {
          name: 'staff_number',
          type: 'remote-search-select',
          label: this.$t('pages.reports.statistics.staff.staff'),
          resource: 'staff',
          optionsValue: 'staff_number',
          fetchHandler: 'getAll',
          computeName: (staff) =>
            this.$formatStaff(staff, ['staff_number', 'fullName'], ' - '),
          modifyQuery: (q) => ({
            q,
            fields: ['staff_number', 'lastname', 'firstname'],
            deleted: false
          })
        },
        {
          name: 'branch_group',
          type: 'remote-search-select',
          doInitialFetch: true,
          label: this.$t('pages.reports.statistics.all.branch_group'),
          resource: 'branchGroups',
          filterable: true,
          optionsValue: 'id',
          disabled: !!this.branch,
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            deleted: false
          })
        },
        {
          name: 'date',
          prop: ['start', 'end'],
          type: 'daterange',
          dateTimeMode: true,
          label: this.$t('pages.transactions.all.filters.date.label'),
          placeholder: this.$t('common.inputs.placeholders.select'),
          closable: false,
          autoClose: false,
          formatValue: (value) => formatDateRange(value, getDateTimeFormat()),
          default: getRangeFor[this.defaultDateSelected]?.(),
          modifyFilter: (filterObject) => ({
            start: filterObject.start,
            end: filterObject.end
          })
        }
      ]
    },
    parsedQuery() {
      const parsedQuery = (qs.parse(this.$route.query) || {}).filter

      return parsedQuery || {}
    },
    parsedDate() {
      return this.parsedQuery.date || {}
    },
    date() {
      return {
        start: new Date(this.parsedDate.start),
        end: new Date(this.parsedDate.end),
        showDateText: true
      }
    },
    resourceQuery() {
      return {
        branch: this.branch || undefined,
        end: this.parsedDate.end || undefined,
        start: this.parsedDate.start || undefined,
        staff_number: this.parsedQuery.staff_number || undefined,
        branch_group: this.parsedQuery.branch_group || undefined
      }
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  mounted() {
    this.$emitter.on('refresh-requested', () => {
      this.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    customResource() {
      const self = this
      return {
        getAll(params) {
          return new Promise((resolve, reject) => {
            th.analytics()
              .getStaffSales(self.resourceQuery)
              .then(({ data }) => {
                const list =
                  data &&
                  data.find(
                    (d) => safeGet(d, 'metric.job') === 'reports_staff_sales'
                  )
                const summary =
                  data &&
                  data.find(
                    (d) =>
                      safeGet(d, 'metric.job') === 'reports_staff_sales.summary'
                  )

                if (summary && summary.values) {
                  if (Array.isArray(summary.values)) {
                    self.summary = summary.values
                  } else {
                    self.summary = [summary.values]
                  }
                }

                if (list && list.values) {
                  resolve({
                    data: list.values
                  })
                }

                resolve({
                  data: []
                })
              })
              .catch((error) => reject(error))
          })
        }
      }
    },
    handleLoadingError() {},
    handleShowOld() {
      this.$emit('change-view')
    },
    async handleExport() {
      this.$ampli.eventWithBaseProps('statisticsExportButtonClick')
      const query = {
        ...this.resourceQuery,
        format: 'csv'
      }

      try {
        const { data } = await th.analytics().getStaffSales(query)

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.reports.statistics.staffSales.staffSales',
            date: new Date(),
            action: {
              entity: 'analytics',
              handler: 'getStaffSales',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.reports.statistics.staffSales.staffSales')
          })
        })
      }
    },
    refresh() {
      this.$nextTick(() => {
        this.$refs.table.refresh()
      })
    }
  }
}
</script>

<style scoped></style>
